import React, {useState, useEffect, useContext} from "react";
import {UserContext} from "../App";


export default function Projects(props) {
  const userf = useContext(UserContext);
  return (
    <div>
 Проекты {userf.username}

    </div>
  );
}
