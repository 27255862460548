import React, { useEffect, useState} from "react";
import Carousel from 'react-bootstrap/Carousel';


export default function Farm(p) {

  const user =  p.user;

  const reg = ()=>{
    setPag('page');
    const payload = {
      user:user.id
    };
// uf_live_admin_6nz5z4rb_7a863f66e4dcc7db155ff041e81402d6   uf_test_admin_6nz5z4rb_e7818f331d4cc7835e4e49b3009bf82c
    const response =  fetch("https://immense-springs-35346.herokuapp.com/command", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

response.then(r=>r.json()).then(p=> {
  let page=' ';
  Object.keys(p).forEach(cc => {
    page +=cc+' '+p[cc]+'; ';

  })
  setPag(page);
});
  }
  const [page, setPage] = useState(null);
  const [pag, setPag] = useState('');
  useEffect(() => {
  if(page !== 'f')  console.log('farm');
    setPage('f');

  });
  return (
    <div>

  <br/>
      {pag}
      <div>{user ? user.username : ''}</div>
      {user ?
        (
      <button className="button" onClick={reg}>Провести </button>

      ) : ('bb')
      }

    </div>
  );
}
