import React, {createContext, useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,

  Navigate,
  useLocation
} from "react-router-dom";
import ReactDOM from 'react-dom';
import Userfront, { PasswordResetForm } from "@userfront/toolkit/react";
import { useTelegramWebApp } from '@telegram-web-app/react';
import TelegramLoginButton from 'react-telegram-login';


import CalendarC from "./components/calendar";
import Nav from "./components/nav";
import Home from "./components/home";
import Dashboard from "./components/dashboard";
import Projects from "./components/projects";
import Team from "./components/team";
import Farm from "./components/farm";

Userfront.init("6nz5z4rb");

export const UserContext = createContext(null);
export const tenant="6nz5z4rb";
export default function App() {

  const telegram = useTelegramWebApp();

  async function makeUser(user) {
    await fetch("https://buben-sha.herokuapp.com/api/ufront/records/member", {
      method: "POST",
      body:JSON.stringify(user),
      headers: {
        'x-apikey': 'today',

      }
    }).then(a=>{


    });
  }


  async function fetchUserData(pro) {
    const response = await fetch("https://buben-sha.herokuapp.com/api/records/member?filter=tgid,eq," + pro.id);
    await  response.json().then(
      a=>{

        if(a.records.length>=1) {
          const r=a.records[0];
          setUserw(a.records[0]);
          const uu=pro;
          uu.rights=r.rghts;
          uu.fid=r.id;
          setUser(uu);
        }else{
         setUserw('senf');
          makeUser({name:pro.username,userfront:'tele',tgid:pro.id});

        }
        //  fetchUserFr();

      //  setUser(a.records[0]);
         }
    )

  }
function callback(){}

  async function fetchUserFr() {

  await fetch("https://api.userfront.com/v0/self", {

      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Userfront.tokens.accessToken
      }

    }).then(r => r.json()).then(a => setUser(a));
  }
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);
  const [userw, setUserw] = useState(null);
  const handleTelegramResponse = response => {
console.log(response);
    if(response.id){


      setUser(response);
      fetchUserData(response);

    }
  };

  useEffect(() => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
  //  let telApi = window.Telegram.WebApp;//

telegram.WebApp.ready();
    telegram.WebApp.expand();

   // telegram.WebApp.requestWriteAccess();
  if(!user && telegram.WebApp.initDataUnsafe.user) {
    setChat(telegram.WebApp.initDataUnsafe.chat ? telegram.WebApp.initDataUnsafe.chat.id : telegram.WebApp.chat_instance);
fetchUserData(telegram.WebApp.initDataUnsafe.user);
const u=telegram.WebApp.initDataUnsafe.user;
u.rights=false;u.fid=false;
    setUser(u);

  }





    //   console.log(telegram.WebApp.initDataUnsafe.user);
if(!user && !telegram.WebApp.initDataUnsafe.user){

    /* fetch("https://api.userfront.com/v0/self", {

       method: "GET",
       headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer " + Userfront.tokens.accessToken
       }

     }).then(r => r.json()).then(a =>   {  setUser(a);});
     */


}else{


}
 //
   });


  return (
    <Router>
<UserContext.Provider value={
  user
  }>
      <Nav fu={handleTelegramResponse}/>


      <div className="lg:container lg:mx-auto md:container md:mx-auto sm:p-4">


        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/farm" element={<Farm/>}/>
          <Route path="/team" element={<Team/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/calendar" element={<CalendarC/>}/>
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard/>
              </RequireAuth>
            }
          />
        </Routes>
      </div>



</UserContext.Provider>
    </Router>
  );
}


function PasswordReset() {
  return (
    <div>
      <h2>Password Reset</h2>
      <PasswordResetForm />
    </div>
  );
}



function RequireAuth({ children }) {
  let location = useLocation();
  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
