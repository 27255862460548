import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import TelegramLoginButton from "react-telegram-login";


export default function TgLog(props) {

  const handleTelegramResponse = response => {
    props.pro(response);

  };

  useEffect(() => {
    ReactDOM.render(
      <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="VlcMemigrationBot"/>,
      document.getElementById('telebut')
    );
  });
  return (
    <>
    <div id="telebut"></div>

    </>
  );
}
