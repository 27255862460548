import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {createConnection} from "../services/fetch";
export default function OverD(p) {

const role = p.user.role;
const id=p.user.fid;
function  rsetNm(e){

  const { name,value } = e.target;  console.log(value); setNm(value);
}
  function  rsetAbout(e){
    const { name,value } = e.target;   setAbout(value);

  }
  async function subs(f) {
    f.preventDefault();
    createConnection().insert('attend',{mem:id,merop:p.event.extendedProps.id});
    setOpen(false);
    return false;
  }
  async function sub(f){
f.preventDefault();


if(p.event){
  createConnection().update('merop',p.event.extendedProps.id,{name:nm,about:about});
}else{
createConnection().insert('merop',{name:nm,about:about,start:Date.parse(p.props)});
}
return false;
 // const query = f.get("username");
  //alert(`You searched for '${query}'`);
}

  const [open, setOpen] = useState(true)
  const [nm, setNm] = useState(p.event ? p.event.title : '')
  const [about, setAbout] = useState(p.event ? p.event.extendedProps.info : '')
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {role === 'author' || role ==='admin' ?


                  (
                    <form onSubmit={sub}>

                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          {!p.event ? (
                            <div>
                          Создать мероприятие {p.props}
                            </div>
                          ) :(
<div>
                            Редактировать мероприятие
</div>
                            )}
                        </Dialog.Title>
                        <div className="mt-2">
                          <div className="text-sm text-gray-500">

                            <div className="space-y-12">
                              <div className="border-b border-gray-900/10 pb-12">
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                  .
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-4">
                                    <label htmlFor="username"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                      Название
                                    </label>
                                    <div className="mt-2">
                                      <div
                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="name"
                                          onChange={rsetNm}
                                          id="username"
value ={nm}
                                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-full">
                                    <label htmlFor="about"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                      Про что?
                                    </label>
                                    <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  onChange={rsetAbout}
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
           value={about}
                />
                                    </div>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">Описание мероприятия.</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      //onClick={() =>sub(this)}
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      type="submit"
                    >
                      Сохранить
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Закрыть
                    </button>
                  </div>
                </form>

                  ):(
                    <div>
                      <form onSubmit={subs}>

                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">

                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                {nm}
                              </Dialog.Title>
                              <div className="mt-2">
                                <div className="text-sm text-gray-500">

                                  <div className="space-y-12">
                                    <div className="border-b border-gray-900/10 pb-4">


                                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


                                        <div className="col-span-full">
                                          <p className="mt-3 text-sm leading-6 text-gray-600">Описание мероприятия.</p>
                                          <div className="mt-2">
                                            {about}
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            //onClick={() =>sub(this)}
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            type="submit"
                          >
                           Подтвердить регистрацию
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Закрыть
                          </button>
                        </div>
                      </form>
                    </div>

                  )}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
