import React, {Fragment, useContext, useEffect, useState} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import {
  Link
} from "react-router-dom";
import Userfront, {LoginForm, LogoutButton} from "@userfront/toolkit";
import {FcCalendar, FcConferenceCall, FcSelfie} from "react-icons/fc";
import Login from "./login";
import {SignupForm} from "@userfront/toolkit/react";
import {UserContext} from "../App";
import ReactDOM from "react-dom";
import TelegramLoginButton from "react-telegram-login";
import TgLog from "./tgLog";


const navigation = [
  { name: 'Ферма', href: 'farm', current: true },
  { name: 'Команда', href: 'team', current: false },
  { name: 'Проекты', href: 'projects', current: false },
  { name: 'Календарь', href: 'calendar', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Nav(fu) {
  // eslint-disable-next-line no-undef

  const userf = useContext(UserContext);

  const [auth, setAuth] = useState(null);
  const [log, setLog] = useState('login');
  function opSign(){
    setLog('sign');
  }
  function opLog(){
    setLog('login');
  }
 async function logout(){
    const response = await fetch("https://api.userfront.com/v0/tenants/6nz5z4rb/auth/logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer "+Userfront.tokens.accessToken
      }
    });
setAuth(null);
Userfront.logout('/');
    console.log(response.json());

  }

  function setLogd(sign) {
 setLog(sign);
  }
  function popo(sign) {
    fu.fu(sign);
  }
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
        <div className="lg:container lg:mx-auto md:container md:mx-auto sm:p-4">

          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={'/'}>
                    <svg version="1.1" className="h-8 w-auto"
                         x="0px" y="0px"
                         width="300px" height="250px" viewBox="0 0 601 500"
                    >

                      <g>
                        <path fill="#AFAAA3" d="M343.6,467.1c-30.4,0-60-14.7-87.8-43.8L28,185.3L192.6,30.4c5,5.4,64.8,69.4,105.4,113
		c10.1,10.9,19.4,22.3,28.4,33.4c22.3,27.5,41.5,51.3,62.5,51.3c8.9,0,17.6-4.2,26.5-13c36-34.5,67.5-41.7,87.5-41.7
		c29.2,0,54,15.9,64.5,41.4c6.4,15.5,7.2,33.3,2.3,51.4c-5.5,20.3-17.6,40-36.1,58.4c-52.5,52.4-94.8,93-95.2,93.4
		C438,418.6,398.5,467.1,343.6,467.1z"/>
                        <path fill="#AFAAA3" d="M192.6,31.1c7,7.5,65.4,70.1,105.1,112.6c10.1,10.8,19.4,22.3,28.3,33.4c22.4,27.6,41.7,51.5,62.9,51.5
		c9.1,0,17.8-4.3,26.8-13.1c35.9-34.4,67.2-41.6,87.2-41.6c14.6,0,28.2,3.9,39.5,11.3c11,7.2,19.5,17.5,24.6,29.8
		c6.4,15.4,7.2,33.1,2.3,51.1c-5.5,20.2-17.6,39.8-36,58.2c-52.5,52.4-94.8,93-95.2,93.4l0,0l0,0.1c-0.1,0.1-10,12.4-26.5,24.4
		c-15.2,11.1-39.3,24.4-67.8,24.4c-30.3,0-59.7-14.7-87.4-43.6L28.7,185.3L192.6,31.1 M192.6,29.7L27.3,185.2
		c0,0,130.7,136.5,228.2,238.4c31.4,32.8,61.6,44,88.2,44c55.7,0,95.1-49.2,95.1-49.2S480.9,378,534,325
		c71.8-71.7,35.9-152.1-31.1-152.1c-26.1,0-56.9,12.2-87.9,41.9c-9.2,9-17.8,12.8-26.1,12.8c-29.1,0-55.1-46.5-90.5-84.5
		C255.9,97.5,192.6,29.7,192.6,29.7L192.6,29.7z"/>
                      </g>
                      <g>
                        <path fill="#AFAAA3" d="M33.4,182.2c-0.9,0-1.5-0.2-1.9-0.6c-2.5-2.6,3.4-13.5,15.9-29.2c13-16.3,31.7-36.5,52.8-56.7
		c39-37.4,76.4-65.5,86.8-65.5c0.9,0,1.5,0.2,1.9,0.6c4.8,5.1-21.6,40.8-68.7,85.9C81.2,154,43.9,182.2,33.4,182.2z"/>
                        <path fill="#AFAAA3" d="M187,29.7l0,1h0c0.7,0,1.3,0.2,1.6,0.5c0.6,0.6,2.7,5-15.9,28.5c-13,16.3-31.7,36.4-52.8,56.6
		c-38.9,37.3-76.1,65.4-86.5,65.4c-0.7,0-1.3-0.2-1.6-0.5c-0.6-0.6-2.7-5,15.9-28.5c13-16.3,31.7-36.4,52.8-56.6
		c38.9-37.3,76.1-65.4,86.5-65.4L187,29.7 M187,29.7c-10.5,0-47.3,27.5-87.2,65.7c-43.7,41.8-74.5,80.6-68.7,86.6
		c0.5,0.5,1.3,0.8,2.3,0.8c10.5,0,47.3-27.5,87.2-65.7c43.7-41.8,74.5-80.6,68.7-86.6C188.8,29.9,188,29.7,187,29.7L187,29.7z"/>
                      </g>
                    </svg>
                  </Link>
                </div>

                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}

                        to={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5"/>
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
                {/* Profile dropdown */}



                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button
                        className="relative flex  bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5"/>
                        <span className="sr-only">Open user menu</span>
                        {auth ?
                          (
                            <FcSelfie size={48}/>
                        ) :
                         userf ?

                         userf.photo_url ? (

                          <img className="w-25 rounded-full" src={userf.photo_url}/>
                         )
                           :
                           (


                             <FcSelfie size={18}/>

                           ) :
                           (

                             <FcConferenceCall size={48}/>
                           )

                        }
                    </Menu.Button>
                  </div>
                  <Transition

                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {auth ? (
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">


                          <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a onClick={logout}
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Выйти
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                    ) :

                      (userf && userf.id) ?  (
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">


                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  Профиль ({userf.username})
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                 Настройки
                                </a>
                              )}
                            </Menu.Item>

                          </Menu.Items>

                        )
                        :
                      (



                    <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {log=='sign' && (    <Menu.Item>

                        <SignupForm />

                      </Menu.Item>
                        )}
                      {log=='login' && (<Menu.Item>

<TgLog pro={popo}/>

                        </Menu.Item>
                      )}

                      {(log=='' || log=='login') && (     <Menu.Item>

                          <a href='#' onClick={opSign} > signup</a>
                      </Menu.Item>
                        )}
                      {(log=='' || log=='sign') && (     <Menu.Item>

                          <a href='#'  onClick={opLog} > login</a>
                        </Menu.Item>
                      )}
                    </Menu.Items>
                      )}
                  </Transition>
                </Menu>


              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">

            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </div>
        </>
      )}
    </Disclosure>
  )
}
