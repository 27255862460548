import Userfront from "@userfront/toolkit";
import React from "react";
import User from "./user";
export default function Dashboard() {
  const userData = JSON.stringify(Userfront.user, null, 2);
console.log(2);
  return (
    <div>
 <User props={Userfront.user}/>
      <button onClick={Userfront.logout}>Logout</button>
    </div>
  );
}
