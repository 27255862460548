import React, {useState, useEffect, useRef, useContext} from "react";

import {SignupForm} from "@userfront/toolkit/react";
import CalendarC from "./calendar";
import {UserContext} from "../App";
import User from "../components/user"
function Home()  {
  const userf = useContext(UserContext);

  return (
    <div className="grid lg:grid-cols-3  lg:gap-2">
      <div className="bg-purple-300 min-h-48 p-3 mt-1">Сообщения

        {(userf && userf.username) ? (

          <div>
          ({userf.username})

          </div>

        ) : ''}


      </div>
      <div className="bg-gray-50-300 min-h-48 p-3 mt-1">Ачивки</div>
      <div className="bg-purple-300 min-h-48 p-3 mt-1">События предстоящие</div>
      <div className="lg:col-span-2  min-h-48 p-3 mt-1"><CalendarC/></div>
      <div className="bg-gray-50 min-h-48 p-3 mt-1">Новости</div>
      {(userf && !userf.username) ? (
        <div className="bg-purple-300 min-h-48 p-3"><SignupForm/></div>

      ): (
        <div className="bg-purple-300 min-h-48 p-3"><User/></div>


      )}


    </div>
  )

}

export default Home;
