import React, { useState, useEffect } from "react";


export default function Team(props) {

  return (
    <div>
Команда

    </div>
  );
}
