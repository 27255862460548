import React, {useState, useEffect, useRef, useContext} from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable }  from "@fullcalendar/interaction";
import {createConnection} from "../services/fetch";
import OverD from "./OverD";
import {UserContext, tenant} from "../App";

function  CalendarC() {
  const userf = useContext(UserContext);
 const role = userf && userf.rights;
  //const role='admin';
  const dragb=   React.createRef();
function onCh(e){
if(role=='admin' || role=='author'){
  createConnection().update('merop',e.event.extendedProps.id,{start:Date.parse(e.event.start),
    endm:e.event.end ? Date.parse(e.event.end) : (Date.parse(e.event.start) + 3600000) });}
}
  useEffect(() => {
createConnection().connect('join=attend').then(a=>{
setEvs(a.records.map(u=>{
  const st=new Date(u.start);
  const en=new Date(u.endm);
  return {
    title: u.name + ' (участвуют '+u.attend.length+')', start: st.toISOString(),end:en.toISOString(),extendedProps:{info:u.about,id:u.id},classNames:['culture']
  }

}));
 // setEvs()

});



  /* */


  }, [])

  const [diaO, setDiaO] = useState(false);
  const [date, setDate] = useState(null);
  const [evento, setEvento] = useState(null);
  const [type, setType] = useState('month');
  const [evs,setEvs]=useState([])

  const drt = (arg) => {
    alert(arg);
  }
  const inf=(i)=>{
    console.log(i);
i.event.eventChange=onCh;
  }
  const handleDateClick = (arg) => {
   const type  = calendarComponentRef.current.calendar.view.type;
if(type.match(/dayGrid/) ){
    calendarComponentRef.current.calendar.changeView('timeGridDay', arg.dateStr);
}else{

   setDate(arg.dateStr);
   }
  }
  const handleEvClick = (arg) => {
    const type  = calendarComponentRef.current.calendar.view.type;
    console.log(arg.event.start)
    if(type.match(/dayGrid/) ){
      calendarComponentRef.current.calendar.changeView('timeGridDay',arg.event.start);
      }else{

      setEvento(arg.event);
      setDiaO(true);

    }
  }
  const handleAdd = (eventInfo) => {

      const newEvent = {

        title: 'new event',
        color: '#ff0000',
        start: eventInfo.event.start
      };
      const evs_=evs;
    evs_.push(newEvent);
  setEvs(evs_);

    calendarComponentRef.current.calendar.render();

   // console.log();


  }
  function renderEventContent(eventInfo) {
    return(
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <i>{eventInfo.event.type}</i>
      </>
    )
  }

  const calendarComponentRef = useRef(null);
  return (
    <>

    <FullCalendar
      locale = 'ru'
      ref={calendarComponentRef}
      plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin ]}
      initialView="dayGridMonth"

      eventClick={handleEvClick}
      editable={true}
      selectable={true}
dateClick={handleDateClick}
      eventReceive={handleAdd}
      droppable={true}
      events={evs}
      eventChange={onCh}
    />
      {diaO && (
      <OverD props={date} event={evento} user={userf}/>

    )}

    </>
  );
}
export  default  CalendarC;
