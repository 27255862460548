export function createConnection() {
  // A real implementation would actually connect to the server
  // telgram bot 6333937641:AAGu1leJS2UaXsKdjz1AM77FZhY757M-aG4
  return {
    update(node, id, data) {

      fetch('https://buben-sha.herokuapp.com/api/ufront/records/' + node + '/' + id,
        {
          method: "PUT",
          body: JSON.stringify(data),

        headers: {
        'x-apikey': 'today',

      }
        }
      )
        .then(response => response.json())
        .then(data => console.log(data));


    },
    insert(node, data) {

      fetch('https://buben-sha.herokuapp.com/api/ufront/records/' + node,
        {
          method: "POST",
          body: JSON.stringify(data),


        headers: {
        'x-apikey': 'today',

      }
        }
      )
        .then(response => response.json())
        .then(data => console.log(data));


    },
    get(ob,filter) {

      return fetch('https://buben-sha.herokuapp.com/api/records/'+ob+'?' + filter)
        .then(response => response.json())



    },
    connect(filter) {

return fetch('https://buben-sha.herokuapp.com/api/records/merop?' + filter)
        .then(response => response.json())



    },
    disconnect() {
      console.log('❌ Disconnected from');
    }
  };
}
